<template>
  <div class="app-tab block">
    <div
      v-for="title, index in items"
      :key="index"
      class="app-tab__item"
      :class="{'is-active': modelValue === index}"
      @click="$emit('update:modelValue', index)"
    >
      <slot :index="index" :title="title">{{ title }}</slot>
    </div>
    <div v-if="$slots.side" class="app-tab__side">
      <slot name="side" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  name: 'AppTab',
  emits: ['update:modelValue'],
  props: {
    items: Array as PropType<string[]>,
    modelValue: {
      type: Number as PropType<number>,
      default: 0
    }
  }
})
</script>
<style lang="less" scoped>
@import url("~@/styles/variables.less");
.app-tab {
  display: flex;
  position: relative;
  margin-bottom: @third_nav_spacing;
  font-size: 14px;

  &__item {
    padding: 0 13px;
    line-height: @third_nav_height;
    cursor: pointer;

    &.is-active {
      position: relative;
      color: @brand_primary;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 40px;
        height: 2px;
        margin-left: -20px;
        background-color: @brand_primary;
      }
    }
  }

  &__side {
    position: absolute;
    line-height: @third_nav_height;
    top: 0;
    right: 30px;
  }
}
</style>
