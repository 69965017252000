
import { defineComponent, PropType, ref, computed, toRef, reactive, watch } from 'vue'
import type { Permission, PermissionFormData } from '@/api/model/systemModel'
import type { Product } from '@/api/model/productModel'
import { getProductList } from '@/api/product'
import { getPermissionById } from '../utils'

const rules = {
  permissionType: [
    { required: true, message: '请选择类型' }
  ],
  permissionCode: [
    { required: true, message: '请输入唯一标识' }
  ],
  permissionName: [
    { required: true, message: '请输入名称' }
  ],
  parentId: [
    {
      required: true,
      validator: (rule, value) => !!value,
      message: '请选择上级菜单'
    }
  ]
}

// 处理权限列表，以便联级选择器使用
function formatPermissions(permissions: Permission[]) {
  return permissions.map(p => ({
    label: p.permissionName,
    value: p.id,
    children: p.children ? formatPermissions(p.children) : null
  }))
}

export default defineComponent({
  name: 'MenuForm',
  emits: ['submit'],
  props: {
    menuType: { // 0: 超管系统, 1: 服务系统, 2: 业务系统
      type: Number as PropType<0 | 1 | 2>,
      required: true
    },
    modelValue: Object as PropType<Permission>,
    permissions: Array as PropType<Permission[]>,
    formId: {
      type: String as PropType<string>,
      default: 'menu_form'
    }
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const menuType = toRef(props, 'menuType')
    const permission = toRef(props, 'modelValue')

    // 状态
    // -----------------------------
    const editMode = computed(() => permission.value != null) // 编辑/新增
    const label = computed(() => formData.permissionType === 3 ? '功能' : '菜单')

    // 表单
    // -----------------------------
    const mainForm = ref()
    const formData = reactive<PermissionFormData>({
      parentId: 0,
      permissionCode: '',
      permissionName: '',
      permissionType: 1,
      permissionUrl: '',
      description: '',
      productTypeList: [],
      dataPermission: 0,
      icon: '',
      sort: ''
    })
    // 填写编辑的原数据
    if (permission.value) {
      const {
        id,
        permissionCode,
        permissionName,
        permissionType,
        permissionUrl,
        description,
        icon,
        sort,
        parentId
      } = permission.value
      formData.id = id
      formData.permissionCode = permissionCode
      formData.permissionName = permissionName
      formData.permissionType = permissionType === 2 ? 3 : (parentId ? 2 : 1)
      formData.permissionUrl = permissionUrl || ''
      formData.description = description || ''
      formData.icon = icon || ''
      formData.sort = String(sort)
      formData.parentId = parentId
    }
    const handleSubmit = () => {
      if (loading.value) {
        return
      }
      const data: PermissionFormData = {
        ...formData
      }
      data.permissionType = data.permissionType === 3 ? 2 : 1
      // 编辑则删除parentId
      if (permission.value) {
        delete data.parentId
      }
      // 超管、业务系统不需要关联产品和数据权限
      if (menuType.value !== 1) {
        delete data.dataPermission
        delete data.productTypeList
      }
      mainForm.value.validate(valid => valid && emit('submit', data))
    }

    // 权限选择器
    const permissions = toRef(props, 'permissions')
    const permissionOptions = computed(() => (
      permissions.value && permissions.value.length > 0
        ? formatPermissions(permissions.value)
        : []
    ))

    // 上级菜单
    const parentId = ref<number[]>([])
    watch(parentId, parentId => {
      if (parentId && parentId.length > 0) {
        formData.parentId = parentId[parentId.length - 1]
        mainForm.value.validateField('parentId')
      } else {
        formData.parentId = 0
      }
    })
    // 已选择的上级菜单
    const selectedParent = computed(() => (
      parentId.value != null && permissions.value
        ? getPermissionById(
          parentId.value[parentId.value.length - 1],
          permissions.value
        )
        : null
    ))
    // 是否显示关联产品
    const productVisible = computed(() => (
      menuType.value === 1 && // 服务系统
      formData.permissionType > 1 && // 非一级菜单
      (selectedParent.value ? !selectedParent.value.parentId : false) // 上级菜单是顶级菜单
    ))
    // 是否显示数据权限
    const dataPermissionVisible = computed(() => (
      menuType.value === 1 &&
      formData.permissionType > 1
    ))

    // 产品列表
    // -----------------------------
    const products = ref<Product[]>([])
    const loadProducts = async () => {
      if (products.value.length > 0) {
        return
      }
      loading.value = true
      const res = await getProductList()
      loading.value = false
      if (res.success && res.result) {
        products.value = res.result
      }
    }
    // 服务系统
    if (menuType.value === 1) {
      loadProducts()
    }
    watch(productVisible, productVisible => productVisible && loadProducts())

    return {
      loading,
      editMode,
      label,
      permissionOptions,
      parentId,
      products,
      productVisible,
      dataPermissionVisible,
      mainForm,
      formData,
      rules,
      handleSubmit
    }
  }
})
