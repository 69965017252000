
import { defineComponent, ref, reactive, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import { MemberParams, Member, Role } from '@/api/model/systemModel'
import MemberForm from './components/MemberForm.vue'
import AppPagination from '@/components/AppPagination.vue'
import { addMember, updateMember, getRoles, getMemberList, deleteMember } from '@/api/system'

const fields = [
  { label: '成员姓名', prop: 'userName' },
  { label: '角色', prop: 'roleName' },
  { label: '登录账号', prop: 'mobile' }
]

export default defineComponent({
  name: 'Member',
  components: { AppPagination, MemberForm },
  setup() {
    const store = useStore()
    const currentUserId = computed(() => store.state.user.userInfo?.id)

    // 角色列表
    // -------------------------------------------------
    const roles = ref<Role[]>([])
    const loadRoles = async () => {
      const res = await getRoles()
      if (res.success && res.result) {
        roles.value = res.result
      }
    }
    loadRoles()

    // 列表加载
    // -------------------------------------------------
    // 搜索
    const searchFormData = reactive<MemberParams>({
      userName: '',
      mobile: '',
      roleId: ''
    })
    const loading = ref(false)
    const rows = ref<Member[]>([])
    const pager = reactive({
      page: 1,
      total: 0,
      pageSize: 10
    })
    const loadList = async (
      page: number = pager.page,
      pageSize: number = pager.pageSize
    ) => {
      loading.value = true
      const res = await getMemberList({
        ...searchFormData,
        pageNo: page,
        pageSize: pageSize
      })
      loading.value = false
      if (res.success && res.result) {
        rows.value = res.result.data
        pager.total = res.result.total
        pager.page = page
        pager.pageSize = pageSize
      }
    }
    loadList()

    const handleSizeChange = (pageSize) => {
      loadList(1, pageSize)
    }
    const handleCurrentChange = (page: number) => {
      loadList(page)
    }

    // 增加/修改
    // -------------------------------------------------
    const submitting = ref(false)
    const dialogVisible = ref(false)
    watch(dialogVisible, v => {
      if (!v) {
        currentRow.value = undefined
      }
    })
    const openDialog = () => {
      dialogVisible.value = true
    }
    const closeDiaolog = () => {
      dialogVisible.value = false
    }

    const handleSubmit = async (formData) => {
      submitting.value = true
      const func = formData.id ? updateMember : addMember
      const res = await func(formData)
      submitting.value = false
      if (res.success) {
        ElMessage.success('操作成功')
        closeDiaolog()
        loadList()
      }
    }

    // 编辑
    // -------------------------------------------------
    const currentRow = ref<Member | undefined>(undefined)
    const handleEdit = (row: Member) => {
      currentRow.value = row
      openDialog()
    }
    const handleDelete = async (row: Member) => {
      const res = await deleteMember(row.id)
      if (res.success) {
        ElMessage.success('操作成功')
        loadList()
      }
    }
    return {
      currentUserId,
      searchFormData,
      loadList,
      loading,
      roles,
      rows,
      pager,
      fields,
      submitting,
      dialogVisible,
      openDialog,
      closeDiaolog,
      handleSubmit,
      handleSizeChange,
      handleCurrentChange,
      handleEdit,
      handleDelete,
      currentRow
    }
  }
})
