import type { Permission } from '@/api/model/systemModel'

// 根据permissionType将子项目分成两个部分
export function divideSubPermissions(permission: Permission) {
  const { subPermission } = permission
  if (subPermission && subPermission.length > 0) {
    const children: Permission[] = []
    const functions: Permission[] = []
    for (let i = 0; i < subPermission.length; i++) {
      const p = subPermission[i]
      // 子菜单
      if (p.permissionType === 1) {
        children.push(p)
      } else {
        functions.push(p)
      }
    }
    if (children.length > 0) {
      permission.children = children
    }
    if (functions.length > 0) {
      permission.functions = functions
    }
  }
}

// 整理数据
// 1. 分类所有的permission的子项目
// 2. 添加到父权限的引用
export function formatPermissions(permissions: Permission[], parent?: Permission) {
  permissions.forEach(permission => {
    if (parent) {
      permission.parent = parent
    }
    divideSubPermissions(permission)
    if (permission.subPermission) {
      formatPermissions(permission.subPermission, permission)
    }
  })
}

export function getChildren({ subPermission }: Permission): Permission[] {
  if (subPermission) {
    let children = subPermission
    subPermission.forEach(p => {
      children = children.concat(getChildren(p))
    })
    return children
  }
  return []
}

export function getParents(permission: Permission): Permission[] {
  let parents: Permission[] = []
  if (permission.parent) {
    parents.push(permission.parent)
    const grandpa = getParents(permission.parent)
    if (grandpa.length > 0) {
      parents = parents.concat(grandpa)
    }
  }
  return parents
}

export function getPermissionById(id: number, permissions: Permission[]): Permission | undefined {
  for (let i = 0, l = permissions.length; i < l; i++) {
    const p = permissions[i]
    if (p.id === id) {
      return p
    }
    if (p.subPermission) {
      const p2 = getPermissionById(id, p.subPermission)
      if (p2) {
        return p2
      }
    }
  }
}

export function getPermissionByIds(permissions: Permission[], ids: number[]): Permission[] {
  const _ids = ids.slice()
  let rs: Permission[] = []
  permissions.forEach(p => {
    const index = _ids.findIndex(id => p.id === id)
    if (index > -1) {
      _ids.splice(index, 1)
      rs.push(p)
    }
    if (_ids.length > 0 && p.subPermission) {
      rs = rs.concat(getPermissionByIds(p.subPermission, _ids))
    }
  })
  return rs
}
