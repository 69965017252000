
import { defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  name: 'AppTab',
  emits: ['update:modelValue'],
  props: {
    items: Array as PropType<string[]>,
    modelValue: {
      type: Number as PropType<number>,
      default: 0
    }
  }
})
