<template>
  <span class="menu-item">
    <span class="menu-item__label">
      <el-checkbox
        :disabled="disabled"
        :model-value="modelValue?.status === 1"
        @update:model-value="$emit('update:checked', $event)"
      >{{ modelValue?.permissionName }}</el-checkbox>
    </span>
    <span
      class="menu-item__edit"
      title="编辑"
      @click="$emit('edit', modelValue)"
    >
      <i class="el-icon-edit" />
    </span>
    <span class="menu-item__delete" title="删除">
      <el-popconfirm title="确定要删除吗？" @confirm="$emit('delete', modelValue.id)">
        <template #reference>
          <i class="el-icon-delete" />
        </template>
      </el-popconfirm>
    </span>
  </span>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import type { Permission } from '@/api/model/systemModel'

export default defineComponent({
  name: 'MenuItem',
  props: {
    modelValue: {
      type: Object as PropType<Permission>,
      required: true
    },
    disabled: Boolean as PropType<boolean>
  }
})
</script>
<style lang="less" scoped>
.menu-item {
  display: inline-block;

  & + & {
    margin-left: 30px;
  }

  &__label,
  &__edit,
  &__delete {
    display: inline-block;
    margin-right: 15px;
  }

  &__edit,
  &__delete {
    cursor: pointer;
  }
}
</style>
