
import { defineComponent, nextTick, ref } from 'vue'
import AppTab from '@/components/AppTab.vue'
import MenuTable from './components/MenuTable.vue'

export default defineComponent({
  name: 'Menu',
  components: { AppTab, MenuTable },
  setup() {
    const currentIndex = ref<0 | 1 | 2>(0)
    const menuTypes = ['超管系统', '服务系统', '业务系统']

    const dialogVisible = ref(false)
    const handleAddClick = () => {
      dialogVisible.value = true
      nextTick(() => {
        dialogVisible.value = false
      })
    }
    return {
      currentIndex,
      menuTypes,
      dialogVisible,
      handleAddClick
    }
  }
})
