
import { defineComponent, PropType } from 'vue'
import type { Permission } from '@/api/model/systemModel'

export default defineComponent({
  name: 'MenuItem',
  props: {
    modelValue: {
      type: Object as PropType<Permission>,
      required: true
    },
    disabled: Boolean as PropType<boolean>
  }
})
