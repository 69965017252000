
import { defineComponent, nextTick, PropType, ref, toRef, watch } from 'vue'
import { ElMessage } from 'element-plus'
import {
  getPermissions,
  addPermission,
  updatePermission,
  deletePermission,
  enablePermission,
  disablePermission,

  getServicePermissions,
  addServicePermission,
  updateServicePermission,
  deleteServicePermission,
  enableServicePermission,
  disableServicePermission,

  getBusinessPermissions,
  addBusinessPermission,
  updateBusinessPermission,
  deleteBusinessPermission,
  enableBusinessPermission,
  disableBusinessPermission
} from '@/api/system'
import type { Permission } from '@/api/model/systemModel'
import MenuItem from './MenuItem.vue'
import MenuForm from './MenuForm.vue'
import {
  formatPermissions,
  getChildren,
  getParents
} from '../utils'

// 列表接口
const loadFunctions = [getPermissions, getServicePermissions, getBusinessPermissions]
const addFunctions = [addPermission, addServicePermission, addBusinessPermission]
const updateFunctions = [updatePermission, updateServicePermission, updateBusinessPermission]
const deleteFunctions = [deletePermission, deleteServicePermission, deleteBusinessPermission]
const enableFunctions = [enablePermission, enableServicePermission, enableBusinessPermission]
const disableFunctions = [disablePermission, disableServicePermission, disableBusinessPermission]

export default defineComponent({
  name: 'MenuTable',
  components: { MenuItem, MenuForm },
  props: {
    menuType: {
      type: Number as PropType<0 | 1 | 2>,
      default: 0 // 0: 超管系统, 1: 服务系统, 2: 业务系统
    },
    dialogVisible: Boolean as PropType<boolean>
  },
  setup(props) {
    const loading = ref(false)
    const menuType = toRef(props, 'menuType')

    // 列表加载
    // -------------------------------------------------
    const tableData = ref<Permission[]>([])
    const loadData = async () => {
      loading.value = true
      const load = loadFunctions[menuType.value]
      const res = await load()
      loading.value = false
      if (res.success && res.result) {
        const rows = res.result
        formatPermissions(rows)
        tableData.value = rows
      } else {
        tableData.value = []
      }
    }
    loadData()
    watch(menuType, loadData)

    // 开启/关闭
    // -------------------------------------------------
    const currentEnableRowIds = ref<number[]>([])
    const handleEnabledChange = (row: Permission) => {
      const enabled = row.status === 1
      const changeTo = !enabled
      let rows = [row]
      // 启用只带上父菜单和子菜单
      if (changeTo) {
        rows = rows.concat(
          getChildren(row)
            .filter(p => p.permissionType === 1)
            .concat(getParents(row))
        )
      } else { // 禁用带上子菜单和功能
        rows = rows.concat(getChildren(row))
      }
      changeEnabled(rows, changeTo)
    }
    const changeEnabled = async (rows: Permission[], enabled) => {
      const ids = rows.map(row => row.id)
      currentEnableRowIds.value = ids
      const enableFunc = enableFunctions[menuType.value]
      const disableFunc = disableFunctions[menuType.value]
      const res = await [disableFunc, enableFunc][Number(enabled)](ids)
      currentEnableRowIds.value = []
      if (res.success) {
        // 更新状态
        rows.forEach(p => {
          p.status = enabled ? 1 : 0
        })
        ElMessage.success('操作成功')
      }
    }

    // 表单
    // -------------------------------------------------
    const thisDialogVisible = ref(false)
    const submitting = ref(false)
    const currentRow = ref<Permission | undefined>()

    watch(() => props.dialogVisible, visible => {
      if (visible) {
        thisDialogVisible.value = true
      }
    })

    watch(thisDialogVisible, v => {
      if (!v) {
        currentRow.value = undefined
      }
    })

    const handleEdit = (row) => {
      currentRow.value = row
      thisDialogVisible.value = true
    }

    const handleSubmit = async (formData) => {
      submitting.value = true
      const add = addFunctions[menuType.value]
      const update = updateFunctions[menuType.value]
      let p
      if (currentRow.value) {
        p = update(formData)
      } else {
        p = add(formData)
      }
      const res = await p
      submitting.value = false
      if (res.success) {
        nextTick(() => {
          thisDialogVisible.value = false
        })
        ElMessage.success('操作成功~')
        loadData()
      }
    }

    // 删除
    const handleDelete = async (id: number) => {
      loading.value = true
      const deleteFunc = deleteFunctions[menuType.value]
      const res = await deleteFunc(id)
      if (res.success) {
        ElMessage.success('删除成功')
        loadData()
      } else {
        loading.value = false
      }
    }

    return {
      loading,
      tableData,
      currentEnableRowIds,
      handleEnabledChange,
      thisDialogVisible,
      submitting,
      currentRow,
      handleEdit,
      handleSubmit,
      handleDelete
    }
  }
})
