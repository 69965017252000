<template>
  <el-form
    id="member_form"
    v-loading="loading"
    ref="mainForm"
    :model="formData"
    :rules="rules"
    label-width="100px"
    class="main-form"
    @submit.prevent="handleSubmit"
  >
    <el-form-item prop="userName" label="成员姓名">
      <el-input v-model="formData.userName" placeholder="请输入成员姓名" />
    </el-form-item>
    <el-form-item prop="mobile" label="登录手机号">
      <el-input v-model="formData.mobile" placeholder="请属于登录手机号" />
    </el-form-item>
    <el-form-item prop="roleId" label="角色">
      <el-select v-model="formData.roleId" placeholder="请选择角色" style="width: 100%">
        <el-option
          v-for="role in roles"
          :key="role.id"
          :label="role.roleName"
          :value="role.id"
        />
      </el-select>
    </el-form-item>
  </el-form>
</template>
<script lang="ts">
import { defineComponent, PropType, ref, reactive, toRef } from 'vue'
import { SimpleMember, Member, Role } from '@/api/model/systemModel'

const rules = {
  userName: [
    { required: true, message: '请输入成员姓名' }
  ],
  mobile: [
    { required: true, message: '请输入登录手机号' }
  ],
  roleId: [
    { required: true, message: '请选择角色' }
  ]
}

export default defineComponent({
  name: 'MemberForm',
  emits: ['submit'],
  props: {
    defaultData: Object as PropType<Member>,
    roles: {
      type: Array as PropType<Role[]>,
      required: true
    }
  },
  setup(props, { emit }) {
    const loading = ref(false)

    const formData = reactive<SimpleMember>({
      userName: '',
      mobile: '',
      roleId: ''
    })

    const mainForm = ref()
    const handleSubmit = () => {
      mainForm.value.validate(valid => {
        if (valid) {
          emit('submit', formData)
        }
      })
    }

    // 回显
    const defaults = toRef(props, 'defaultData')
    if (defaults.value) {
      formData.id = defaults.value.id
      formData.userName = defaults.value.userName
      formData.mobile = defaults.value.mobile
      formData.roleId = defaults.value.roleId
    }

    return {
      loading,
      formData,
      mainForm,
      rules,
      handleSubmit
    }
  }
})
</script>
