
import { defineComponent, PropType, ref, reactive, toRef } from 'vue'
import { SimpleMember, Member, Role } from '@/api/model/systemModel'

const rules = {
  userName: [
    { required: true, message: '请输入成员姓名' }
  ],
  mobile: [
    { required: true, message: '请输入登录手机号' }
  ],
  roleId: [
    { required: true, message: '请选择角色' }
  ]
}

export default defineComponent({
  name: 'MemberForm',
  emits: ['submit'],
  props: {
    defaultData: Object as PropType<Member>,
    roles: {
      type: Array as PropType<Role[]>,
      required: true
    }
  },
  setup(props, { emit }) {
    const loading = ref(false)

    const formData = reactive<SimpleMember>({
      userName: '',
      mobile: '',
      roleId: ''
    })

    const mainForm = ref()
    const handleSubmit = () => {
      mainForm.value.validate(valid => {
        if (valid) {
          emit('submit', formData)
        }
      })
    }

    // 回显
    const defaults = toRef(props, 'defaultData')
    if (defaults.value) {
      formData.id = defaults.value.id
      formData.userName = defaults.value.userName
      formData.mobile = defaults.value.mobile
      formData.roleId = defaults.value.roleId
    }

    return {
      loading,
      formData,
      mainForm,
      rules,
      handleSubmit
    }
  }
})
