<template>
  <app-page>
    <block-title>{{ editType ? '编辑' : '新增' }}角色</block-title>
    <el-form
      v-loading="loading"
      ref="mainForm"
      :model="formData"
      :label-width="100"
      :rules="rules"
      class="role-form"
      @submit.prevent="handleSubmit"
    >
      <el-form-item label="角色名称" prop="roleName">
        <el-input show-word-limit maxlength="10" placeholder="请输入角色名称" v-model="formData.roleName" />
      </el-form-item>
      <el-form-item label="角色描述" prop="description">
        <el-input show-word-limit maxlength="30" placeholder="请输入角色描述" v-model="formData.description" />
      </el-form-item>
      <el-form-item v-if="menuType === 1" label="数据权限">
        <div class="sub-item">
          可查看
          <el-select v-model="formData.readType" placeholder="请选择">
            <el-option label="全部" :value="0" />
            <el-option label="自己负责的" :value="1" />
          </el-select>
          数据
        </div>
        <div class="sub-item">
          可操作
          <el-select v-model="formData.writeType" placeholder="请选择">
            <el-option label="全部" :value="0" />
            <el-option label="自己负责的" :value="1" />
          </el-select>
          数据
        </div>
      </el-form-item>
      <el-form-item label="功能权限">
        <menu-table v-model="formData.permissionIds" :menu-type="menuType" />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          native-type="submit"
          :loading="submitting"
        >确认提交</el-button>
        <el-button @click="$router.push({ name: 'system_roles' })">返回</el-button>
      </el-form-item>
    </el-form>
  </app-page>
</template>
<script lang="ts">
import { defineComponent, ref, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import type { Role } from '@/api/model/systemModel'
import MenuTable from './components/MenuTable.vue'
import {
  addRole,
  updateRole,
  addServiceRole,
  addBusinessRole,
  updateServiceRole,
  updateBusinessRole,

  getRole,
  getServiceRole,
  getBusinessRole
} from '@/api/system'

const rules = {
  roleName: {
    required: true,
    message: '请输入角色名称'
  }
}

const loadDetailFunctions = [getRole, getServiceRole, getBusinessRole]
const addFunctions = [addRole, addServiceRole, addBusinessRole]
const updateFunctions = [updateRole, updateServiceRole, updateBusinessRole]

export default defineComponent({
  name: 'RoleEdit',
  components: { MenuTable },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { id } = route.query
    const editType = !!id

    const mainForm = ref()
    const loading = ref(false)

    const menuType: number = (
      route.query.menuType
        ? Number(route.query.menuType)
        : 0
    )

    // 表单
    // -----------------------------------------
    const submitting = ref(false)
    const formData = reactive<Role>({
      roleName: '',
      description: '',
      permissionIds: []
    })

    const handleSubmit = () => {
      mainForm.value.validate(valid => valid && submit())
    }

    const submit = async () => {
      submitting.value = true
      const typeFunctions = id ? updateFunctions : addFunctions
      const func = typeFunctions[menuType]
      const res = await func(formData)
      submitting.value = false
      if (res.success) {
        ElMessage.success('操作成功')
        router.push({ name: 'system_roles' })
      }
    }

    // 加载详情
    // -----------------------------------------
    const loadDetail = async () => {
      const load = loadDetailFunctions[menuType]
      loading.value = true
      const res = await load(Number(id))
      loading.value = false
      if (res.success && res.result) {
        const apiData = res.result
        formData.id = apiData.id
        formData.roleName = apiData.roleName
        formData.description = apiData.description
        formData.permissionIds = apiData.permissionIds
        // 服务系统添加额外数据
        if (menuType === 1) {
          formData.readType = apiData.readType
          formData.writeType = apiData.writeType
        }
      }
    }
    if (id) {
      loadDetail()
    }

    return {
      menuType,
      editType,
      mainForm,
      loading,
      submitting,
      formData,
      rules,
      handleSubmit
    }
  }
})
</script>
<style lang="less" scoped>
.role-form {
  :deep(.el-form-item__content) {
    overflow: auto;
  }
}
.sub-item {
  & + & {
    margin-top: 10px;
  }
}
</style>
