<template>
  <el-table
    v-loading="loading"
    :data="tableData"
    row-key="id"
    border
    default-expand-all
    class="menu-table"
  >
    <el-table-column label="菜单名称" prop="permissionName" width="300px">
      <template #="{ row }">
        <el-checkbox
          v-model="row.checked"
          :indeterminate="getIndeterminate(row)"
          @change="handleMenuCheckedChange(row, $event, true)"
        >
          {{ row.permissionName }}
        </el-checkbox>
      </template>
    </el-table-column>
    <el-table-column label="描述" prop="description" :formatter="descriptionFormatter" />
    <el-table-column label="创建时间" prop="createdAt" :formatter="timeFormatter" />
    <el-table-column label="功能">
      <template #="{ row }">
        <el-checkbox
          v-for="func in row.functions"
          :key="func.id"
          v-model="func.checked"
          @change="handleFunctionCheckedChange(func, $event)"
        >{{ func?.permissionName }}</el-checkbox>
      </template>
    </el-table-column>
  </el-table>
</template>
<script lang="ts">
import { defineComponent, PropType, ref, watch } from 'vue'
import type { Permission } from '@/api/model/systemModel'
import {
  getPermissions,
  getServicePermissions,
  getBusinessPermissions
} from '@/api/system'
import { formatPermissions } from '@/views/system/menu/utils'
import { dateFormat } from '@/utils/date'

const loadFunctions = [getPermissions, getServicePermissions, getBusinessPermissions]

export default defineComponent({
  name: 'MenuTable',
  props: {
    modelValue: {
      type: Array as PropType<number[]>,
      default: () => []
    },
    menuType: {
      type: Number as PropType<number>,
      default: 0
    }
  },
  setup(props, { emit }) {
    const loading = ref(false)

    // 权限列表
    const tableData = ref<Permission[]>([])
    const loadPermissions = async () => {
      const load = loadFunctions[props.menuType]
      loading.value = true
      const res = await load()
      loading.value = false
      if (res.success && res.result) {
        const rows = res.result.map(row => ({
          ...row,
          checked: false
        }))
        formatPermissions(rows)
        tableData.value = rows

        // 回显
        if (props.modelValue && props.modelValue.length > 0) {
          checkIds(props.modelValue)
        }
      } else {
        tableData.value = []
      }
    }
    loadPermissions()

    // 过滤
    const timeFormatter = (row, col) => dateFormat(row[col.property])
    const descriptionFormatter = (row, col) => row[col.property] || '--'
    const getIndeterminate = (row: Permission): boolean => {
      const { children } = row
      if (children && children.length) {
        const checked = children.filter(row => row.checked)
        return checked.length > 0 && checked.length < children.length
      }
      return false
    }

    // 选中
    // -----------------------------------------
    const getCheckedIds = (rows: Permission[] = tableData.value): number[] => {
      let checkedIds: number[] = []
      rows.forEach(row => {
        if (row.checked) {
          checkedIds.push(row.id)
        }
        if (row.subPermission) {
          const checkedChildren = getCheckedIds(row.subPermission)
          if (checkedChildren.length) {
            checkedIds = checkedIds.concat(checkedChildren)
          }
        }
      })
      return checkedIds
    }

    const checkParents = (row: Permission) => {
      if (row.parent) {
        row.parent.checked = true
        checkParents(row.parent)
      }
    }
    const handleMenuCheckedChange = (row: Permission, checked, isTop = false) => {
      if (row.subPermission) {
        row.subPermission.forEach(child => {
          child.checked = checked
          handleMenuCheckedChange(child, checked)
        })
      }
      if (checked) {
        checkParents(row)
      }
      if (row.parent) {
        const siblings = row.parent[row.permissionType === 1 ? 'children' : 'functions']
        if (siblings && siblings.length > 0) {
          const checkedSiblings = siblings.filter(item => item.checked)
          if (checkedSiblings.length === siblings.length) {
            row.parent.checked = true
          } else if (checkedSiblings.length === 0) {
            row.parent.checked = false
          }
        }
      }
      if (isTop) {
        emit('update:modelValue', getCheckedIds())
      }
    }
    const handleFunctionCheckedChange = (row: Permission, checked) => {
      if (checked) {
        checkParents(row)
      }
      emit('update:modelValue', getCheckedIds())
    }

    // 回显
    const checkIds = (ids: number[], rows = tableData.value) => {
      if (!ids) {
        return
      }
      ids = ids.slice()
      rows.forEach(row => {
        const index = ids.findIndex(id => id === row.id)
        if (index > -1) {
          row.checked = true
          ids.splice(index, 1)
        } else {
          row.checked = false
        }
        if (row.subPermission) {
          checkIds(ids, row.subPermission)
        }
      })
    }
    watch(() => props.modelValue, (checkedIds) => {
      checkIds(checkedIds)
    })

    return {
      loading,
      tableData,
      timeFormatter,
      descriptionFormatter,
      getIndeterminate,
      handleMenuCheckedChange,
      handleFunctionCheckedChange
    }
  }
})
</script>
<style lang="less" scoped>
.menu-table {
  :deep(th) {
    line-height: 1;
  }
}
</style>
