
import { defineComponent, ref } from 'vue'
import { getTemplates, uploadTemplate } from '@/api/system'
import type { FileTemplate } from '@/api/model/systemModel'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'Templates',
  setup() {
    const loading = ref(false)

    const rows = ref<FileTemplate[]>([])
    const loadList = async () => {
      loading.value = true
      const res = await getTemplates()
      loading.value = false
      if (res.success && res.result) {
        rows.value = res.result
      }
    }
    loadList()

    // 上传
    // ---------------------------------------
    const uploadingId = ref(0)
    const upload = async (option) => {
      const { file } = option
      const { id } = option.data
      uploadingId.value = id
      const res = await uploadTemplate(id, file)
      uploadingId.value = 0
      if (res.success) {
        ElMessage.success('上传成功')
        loadList()
      }
    }

    return {
      loading,
      uploadingId,
      rows,
      upload
    }
  }
})
