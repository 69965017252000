<template>
  <el-form
    v-loading="loading"
    ref="mainForm"
    :model="formData"
    :id="formId"
    :rules="rules"
    label-width="80px"
    class="main-form"
    @submit.prevent="handleSubmit"
  >
    <el-form-item label="权限类型" prop="permissionType" style="width: 100%">
      <el-radio-group :disabled="editMode" v-model="formData.permissionType">
        <el-radio :label="1">一级菜单</el-radio>
        <el-radio :label="2">{{ menuType === 0 ? '二级菜单' : '次级菜单' }}</el-radio>
        <el-radio :label="3">功能</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item :label="`${label}名称`" prop="permissionName">
        <el-input maxlength="10" show-word-limit v-model="formData.permissionName" />
    </el-form-item>
    <el-form-item :label="`${label}描述`" prop="description">
      <el-input maxlength="20" show-word-limit v-model="formData.description" />
    </el-form-item>
    <el-form-item label="排序" prop="sort">
      <el-input maxlength="20" type="number" v-model="formData.sort" />
    </el-form-item>
    <el-form-item label="图标" prop="icon">
      <el-input maxlength="20" v-model="formData.icon" />
    </el-form-item>
    <el-form-item label="路径" prop="permissionUrl">
      <el-input v-model="formData.permissionUrl" />
    </el-form-item>
    <el-form-item
      v-if="formData.permissionType === 3 || formData.permissionType === 2"
      label="上级菜单"
      prop="parentId"
    >
      <el-select
        v-if="modelValue?.parent"
        disabled
        :model-value="formData.parentId"
        style="width: 100%"
      >
        <el-option :value="modelValue?.parent?.id" :label="modelValue?.parent?.permissionName" />
      </el-select>
      <el-cascader
        v-else
        v-model="parentId"
        :options="permissionOptions"
        :props="{ checkStrictly: true }"
        clearable
        style="width: 100%"
      />
    </el-form-item>
    <el-form-item
      v-if="productVisible"
      label="关联产品"
      prop="productTypeList"
    >
      <el-select v-model="formData.productTypeList" multiple placeholder="请选择">
        <el-option
          v-for="item in products"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
    </el-form-item>
    <el-form-item
      v-if="dataPermissionVisible"
      label="数据权限"
      prop="dataPermission"
    >
      <el-radio-group v-model="formData.dataPermission">
        <el-radio :label="1">开启</el-radio>
        <el-radio :label="0">关闭</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="唯一标识" prop="permissionCode">
      <el-input v-model="formData.permissionCode" />
      <el-tooltip placement="top" effect="light">
        <span class="tooltip"><i class="el-icon-info"></i>命名规则</span>
        <template #content>
          <div style="width: 300px">
            一级菜单唯一标识：organize，二级菜单唯一标识：organize_organizeManage，三级菜单或者功能唯一标识：organize_organizeManage_add
          </div>
        </template>
      </el-tooltip>
    </el-form-item>
  </el-form>
</template>
<script lang="ts">
import { defineComponent, PropType, ref, computed, toRef, reactive, watch } from 'vue'
import type { Permission, PermissionFormData } from '@/api/model/systemModel'
import type { Product } from '@/api/model/productModel'
import { getProductList } from '@/api/product'
import { getPermissionById } from '../utils'

const rules = {
  permissionType: [
    { required: true, message: '请选择类型' }
  ],
  permissionCode: [
    { required: true, message: '请输入唯一标识' }
  ],
  permissionName: [
    { required: true, message: '请输入名称' }
  ],
  parentId: [
    {
      required: true,
      validator: (rule, value) => !!value,
      message: '请选择上级菜单'
    }
  ]
}

// 处理权限列表，以便联级选择器使用
function formatPermissions(permissions: Permission[]) {
  return permissions.map(p => ({
    label: p.permissionName,
    value: p.id,
    children: p.children ? formatPermissions(p.children) : null
  }))
}

export default defineComponent({
  name: 'MenuForm',
  emits: ['submit'],
  props: {
    menuType: { // 0: 超管系统, 1: 服务系统, 2: 业务系统
      type: Number as PropType<0 | 1 | 2>,
      required: true
    },
    modelValue: Object as PropType<Permission>,
    permissions: Array as PropType<Permission[]>,
    formId: {
      type: String as PropType<string>,
      default: 'menu_form'
    }
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const menuType = toRef(props, 'menuType')
    const permission = toRef(props, 'modelValue')

    // 状态
    // -----------------------------
    const editMode = computed(() => permission.value != null) // 编辑/新增
    const label = computed(() => formData.permissionType === 3 ? '功能' : '菜单')

    // 表单
    // -----------------------------
    const mainForm = ref()
    const formData = reactive<PermissionFormData>({
      parentId: 0,
      permissionCode: '',
      permissionName: '',
      permissionType: 1,
      permissionUrl: '',
      description: '',
      productTypeList: [],
      dataPermission: 0,
      icon: '',
      sort: ''
    })
    // 填写编辑的原数据
    if (permission.value) {
      const {
        id,
        permissionCode,
        permissionName,
        permissionType,
        permissionUrl,
        description,
        icon,
        sort,
        parentId
      } = permission.value
      formData.id = id
      formData.permissionCode = permissionCode
      formData.permissionName = permissionName
      formData.permissionType = permissionType === 2 ? 3 : (parentId ? 2 : 1)
      formData.permissionUrl = permissionUrl || ''
      formData.description = description || ''
      formData.icon = icon || ''
      formData.sort = String(sort)
      formData.parentId = parentId
    }
    const handleSubmit = () => {
      if (loading.value) {
        return
      }
      const data: PermissionFormData = {
        ...formData
      }
      data.permissionType = data.permissionType === 3 ? 2 : 1
      // 编辑则删除parentId
      if (permission.value) {
        delete data.parentId
      }
      // 超管、业务系统不需要关联产品和数据权限
      if (menuType.value !== 1) {
        delete data.dataPermission
        delete data.productTypeList
      }
      mainForm.value.validate(valid => valid && emit('submit', data))
    }

    // 权限选择器
    const permissions = toRef(props, 'permissions')
    const permissionOptions = computed(() => (
      permissions.value && permissions.value.length > 0
        ? formatPermissions(permissions.value)
        : []
    ))

    // 上级菜单
    const parentId = ref<number[]>([])
    watch(parentId, parentId => {
      if (parentId && parentId.length > 0) {
        formData.parentId = parentId[parentId.length - 1]
        mainForm.value.validateField('parentId')
      } else {
        formData.parentId = 0
      }
    })
    // 已选择的上级菜单
    const selectedParent = computed(() => (
      parentId.value != null && permissions.value
        ? getPermissionById(
          parentId.value[parentId.value.length - 1],
          permissions.value
        )
        : null
    ))
    // 是否显示关联产品
    const productVisible = computed(() => (
      menuType.value === 1 && // 服务系统
      formData.permissionType > 1 && // 非一级菜单
      (selectedParent.value ? !selectedParent.value.parentId : false) // 上级菜单是顶级菜单
    ))
    // 是否显示数据权限
    const dataPermissionVisible = computed(() => (
      menuType.value === 1 &&
      formData.permissionType > 1
    ))

    // 产品列表
    // -----------------------------
    const products = ref<Product[]>([])
    const loadProducts = async () => {
      if (products.value.length > 0) {
        return
      }
      loading.value = true
      const res = await getProductList()
      loading.value = false
      if (res.success && res.result) {
        products.value = res.result
      }
    }
    // 服务系统
    if (menuType.value === 1) {
      loadProducts()
    }
    watch(productVisible, productVisible => productVisible && loadProducts())

    return {
      loading,
      editMode,
      label,
      permissionOptions,
      parentId,
      products,
      productVisible,
      dataPermissionVisible,
      mainForm,
      formData,
      rules,
      handleSubmit
    }
  }
})
</script>
<style lang="less" scoped>
@import url("~@/styles/variables.less");
.main-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  :deep(.el-form-item) {
    width: 48%;
  }
}

.tooltip {
  font-size: 13px;
  line-height: 1;

  i {
    display: inline-block;
    margin-right: 8px;
    font-size: 14px;
    color: @brand_primary;
    vertical-align: middle;
  }
}
</style>
