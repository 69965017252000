
import { defineComponent, ref, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import type { Role } from '@/api/model/systemModel'
import MenuTable from './components/MenuTable.vue'
import {
  addRole,
  updateRole,
  addServiceRole,
  addBusinessRole,
  updateServiceRole,
  updateBusinessRole,

  getRole,
  getServiceRole,
  getBusinessRole
} from '@/api/system'

const rules = {
  roleName: {
    required: true,
    message: '请输入角色名称'
  }
}

const loadDetailFunctions = [getRole, getServiceRole, getBusinessRole]
const addFunctions = [addRole, addServiceRole, addBusinessRole]
const updateFunctions = [updateRole, updateServiceRole, updateBusinessRole]

export default defineComponent({
  name: 'RoleEdit',
  components: { MenuTable },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { id } = route.query
    const editType = !!id

    const mainForm = ref()
    const loading = ref(false)

    const menuType: number = (
      route.query.menuType
        ? Number(route.query.menuType)
        : 0
    )

    // 表单
    // -----------------------------------------
    const submitting = ref(false)
    const formData = reactive<Role>({
      roleName: '',
      description: '',
      permissionIds: []
    })

    const handleSubmit = () => {
      mainForm.value.validate(valid => valid && submit())
    }

    const submit = async () => {
      submitting.value = true
      const typeFunctions = id ? updateFunctions : addFunctions
      const func = typeFunctions[menuType]
      const res = await func(formData)
      submitting.value = false
      if (res.success) {
        ElMessage.success('操作成功')
        router.push({ name: 'system_roles' })
      }
    }

    // 加载详情
    // -----------------------------------------
    const loadDetail = async () => {
      const load = loadDetailFunctions[menuType]
      loading.value = true
      const res = await load(Number(id))
      loading.value = false
      if (res.success && res.result) {
        const apiData = res.result
        formData.id = apiData.id
        formData.roleName = apiData.roleName
        formData.description = apiData.description
        formData.permissionIds = apiData.permissionIds
        // 服务系统添加额外数据
        if (menuType === 1) {
          formData.readType = apiData.readType
          formData.writeType = apiData.writeType
        }
      }
    }
    if (id) {
      loadDetail()
    }

    return {
      menuType,
      editType,
      mainForm,
      loading,
      submitting,
      formData,
      rules,
      handleSubmit
    }
  }
})
