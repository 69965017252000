<template>
  <app-tab v-model="currentIndex" :items="menuTypes" class="sticky-box" >
    <template #side>
      <a href="javascript:void(0)" @click="handleAddClick">+ 添加权限</a>
    </template>
  </app-tab>
  <app-page>
    <menu-table :menu-type="currentIndex" :dialog-visible="dialogVisible" />
  </app-page>
</template>
<script lang="ts">
import { defineComponent, nextTick, ref } from 'vue'
import AppTab from '@/components/AppTab.vue'
import MenuTable from './components/MenuTable.vue'

export default defineComponent({
  name: 'Menu',
  components: { AppTab, MenuTable },
  setup() {
    const currentIndex = ref<0 | 1 | 2>(0)
    const menuTypes = ['超管系统', '服务系统', '业务系统']

    const dialogVisible = ref(false)
    const handleAddClick = () => {
      dialogVisible.value = true
      nextTick(() => {
        dialogVisible.value = false
      })
    }
    return {
      currentIndex,
      menuTypes,
      dialogVisible,
      handleAddClick
    }
  }
})
</script>
<style lang="less" scoped>
.page-content {
  margin-top: 20px;
}
.sticky-box{
  position: sticky;
  top: 50px;
  z-index: 99;
}
</style>
