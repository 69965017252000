<template>
  <app-page>
    <el-table
      v-loading="loading"
      border
      :data="rows"
      class="mt2"
    >
      <el-table-column prop="modelName" label="模块" />
      <el-table-column prop="fileName" label="表格模板">
        <template #="{ row }">
          <template v-if="row.fileName">
            <a v-if="row.url" :href="row.url">{{ row.fileName }}</a>
            <template v-else>{{ row.fileName }}</template>
          </template>
          <template v-else>--</template>
        </template>
      </el-table-column>
      <el-table-column label="编辑" :width="150">
        <template #="{ row }">
          <el-upload
            action=""
            :data="{ id: row.id }"
            :show-file-list="false"
            :disabled="uploadingId === row.id"
            :http-request="upload"
          >
            <el-button
              type="text"
              size="mini"
              :loading="uploadingId === row.id"
            >替换</el-button>
          </el-upload>
        </template>
      </el-table-column>
    </el-table>
  </app-page>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { getTemplates, uploadTemplate } from '@/api/system'
import type { FileTemplate } from '@/api/model/systemModel'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'Templates',
  setup() {
    const loading = ref(false)

    const rows = ref<FileTemplate[]>([])
    const loadList = async () => {
      loading.value = true
      const res = await getTemplates()
      loading.value = false
      if (res.success && res.result) {
        rows.value = res.result
      }
    }
    loadList()

    // 上传
    // ---------------------------------------
    const uploadingId = ref(0)
    const upload = async (option) => {
      const { file } = option
      const { id } = option.data
      uploadingId.value = id
      const res = await uploadTemplate(id, file)
      uploadingId.value = 0
      if (res.success) {
        ElMessage.success('上传成功')
        loadList()
      }
    }

    return {
      loading,
      uploadingId,
      rows,
      upload
    }
  }
})
</script>
