<template>
  <app-tab v-model="menuType" :items="menuTypes">
    <template #side>
      <router-link :to="{
        name: 'system_roles_edit',
        query: { menuType: menuType }
      }">添加角色</router-link>
    </template>
  </app-tab>
  <app-page>
    <el-table
      v-loading="loading"
      :data="rows"
      border
      class="mt2"
    >
      <el-table-column
        v-for="item in fields"
        :key="item.prop"
        :prop="item.prop"
        :label="item.label"
        :formatter="item.formatter"
      />
      <el-table-column label="操作">
        <template #="{ row }">
          <el-button
            type="text"
            size="mini"
            @click="toEdit(row.id)"
          >编辑</el-button>
          <el-popconfirm
            v-if="menuType !== 1"
            title="确定删除该角色吗？删除后对应角色账号将无任何操作权限。"
            @confirm="deleteRow(row.id)"
          >
            <template #reference>
              <el-button type="text" size="mini">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </app-page>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import AppTab from '@/components/AppTab.vue'
import {
  getRoles,
  getServiceRoles,
  getBusinessRoles,
  deleteRole,
  deleteBusinessRole
} from '@/api/system'
import type { Role } from '@/api/model/systemModel'
import { dateFormat } from '@/utils/date'
import { ElMessage } from 'element-plus'

const loadFunctions = [getRoles, getServiceRoles, getBusinessRoles]

const fields = [
  { label: '角色名称', prop: 'roleName' },
  {
    label: '描述',
    prop: 'description',
    formatter: (row, col) => row[col.property] || '--'
  },
  {
    label: '创建时间',
    prop: 'createdAt',
    formatter: (row, col) => dateFormat(row[col.property])
  }
]

export default defineComponent({
  name: 'Role',
  components: { AppTab },
  setup() {
    const router = useRouter()

    // Tab
    // -------------------------------------
    const menuType = ref<0 | 1 | 2>(0)
    const menuTypes = ['超管系统', '服务系统', '业务系统']

    const loading = ref(false)
    const rows = ref<Role[]>([])

    const loadList = async () => {
      const load = loadFunctions[menuType.value]
      loading.value = true
      const res = await load()
      loading.value = false
      if (res.success && res.result) {
        rows.value = res.result
      } else {
        rows.value = []
      }
    }
    loadList()
    watch(menuType, () => {
      rows.value = []
      loadList()
    })

    const toEdit = (id?: number) => {
      const query: Record<string, string | number> = { menuType: menuType.value }
      if (id != null) {
        query.id = id
      }
      router.push({
        name: 'system_roles_edit',
        query
      })
    }

    // 删除
    // ----------------------------------------
    const deleteRow = async (id: number) => {
      if (menuType.value === 1) {
        return
      }
      const deleteFunction = (
        menuType.value === 0
          ? deleteRole
          : deleteBusinessRole
      )
      const res = await deleteFunction(id)
      if (res.success) {
        ElMessage.success('删除成功')
        loadList()
      }
    }

    return {
      menuType,
      menuTypes,
      loading,
      fields,
      rows,
      toEdit,
      deleteRow
    }
  }
})
</script>
<style lang="less" scoped>
.menu-toolbar {
  margin: 10px 0;
}
</style>
