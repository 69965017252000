<template>
  <app-page>
    <search-bar>
      <el-form inline :model="searchFormData" @submit.prevent="loadList(1)">
        <el-form-item prop="userName">
          <el-input v-model="searchFormData.userName" placeholder="请输入成员姓名" />
        </el-form-item>
        <el-form-item prop="mobile">
          <el-input v-model="searchFormData.mobile" placeholder="请输入登录账号" />
        </el-form-item>
        <el-form-item prop="roleId" label="角色">
          <el-select v-model="searchFormData.roleId" placeholder="请选择角色" clearable>
            <el-option
              v-for="role in roles"
              :key="role.id"
              :label="role.roleName"
              :value="role.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" native-type="submit">查询</el-button>
        </el-form-item>
      </el-form>
      <template #side>
        <el-button icon="el-icon-plus" @click="openDialog">新增账户</el-button>
      </template>
    </search-bar>
    <el-table
      v-loading="loading"
      :data="rows"
      border
    >
      <el-table-column
        v-for="item in fields"
        :key="item.prop"
        :prop="item.prop"
        :label="item.label"
      />
      <el-table-column>
        <template #="{ row }">
          <el-button type="text" size="mini" @click="handleEdit(row)">编辑</el-button>
          <el-popconfirm
            v-if="row.id !== currentUserId"
            title="确定要删除吗？"
            @confirm="handleDelete(row)"
          >
            <template #reference>
              <el-button type="text" size="mini">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <app-pagination
      :current-page="pager.page"
      :page-size="pager.pageSize"
      :total="pager.total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </app-page>
  <el-dialog
    v-model="dialogVisible"
    :title="currentRow ? '编辑成员' : '添加成员'"
    width="500px"
  >
    <member-form
      :roles="roles"
      :default-data="currentRow"
      :key="currentRow ? currentRow.id : Math.random()"
      @submit="handleSubmit"
    />
    <template #footer>
      <el-button @click="closeDiaolog">取消</el-button>
      <el-button
        type="primary"
        native-type="submit"
        form="member_form"
        :loading="submitting"
      >确认</el-button>
    </template>
  </el-dialog>
</template>
<script lang="ts">
import { defineComponent, ref, reactive, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import { MemberParams, Member, Role } from '@/api/model/systemModel'
import MemberForm from './components/MemberForm.vue'
import AppPagination from '@/components/AppPagination.vue'
import { addMember, updateMember, getRoles, getMemberList, deleteMember } from '@/api/system'

const fields = [
  { label: '成员姓名', prop: 'userName' },
  { label: '角色', prop: 'roleName' },
  { label: '登录账号', prop: 'mobile' }
]

export default defineComponent({
  name: 'Member',
  components: { AppPagination, MemberForm },
  setup() {
    const store = useStore()
    const currentUserId = computed(() => store.state.user.userInfo?.id)

    // 角色列表
    // -------------------------------------------------
    const roles = ref<Role[]>([])
    const loadRoles = async () => {
      const res = await getRoles()
      if (res.success && res.result) {
        roles.value = res.result
      }
    }
    loadRoles()

    // 列表加载
    // -------------------------------------------------
    // 搜索
    const searchFormData = reactive<MemberParams>({
      userName: '',
      mobile: '',
      roleId: ''
    })
    const loading = ref(false)
    const rows = ref<Member[]>([])
    const pager = reactive({
      page: 1,
      total: 0,
      pageSize: 10
    })
    const loadList = async (
      page: number = pager.page,
      pageSize: number = pager.pageSize
    ) => {
      loading.value = true
      const res = await getMemberList({
        ...searchFormData,
        pageNo: page,
        pageSize: pageSize
      })
      loading.value = false
      if (res.success && res.result) {
        rows.value = res.result.data
        pager.total = res.result.total
        pager.page = page
        pager.pageSize = pageSize
      }
    }
    loadList()

    const handleSizeChange = (pageSize) => {
      loadList(1, pageSize)
    }
    const handleCurrentChange = (page: number) => {
      loadList(page)
    }

    // 增加/修改
    // -------------------------------------------------
    const submitting = ref(false)
    const dialogVisible = ref(false)
    watch(dialogVisible, v => {
      if (!v) {
        currentRow.value = undefined
      }
    })
    const openDialog = () => {
      dialogVisible.value = true
    }
    const closeDiaolog = () => {
      dialogVisible.value = false
    }

    const handleSubmit = async (formData) => {
      submitting.value = true
      const func = formData.id ? updateMember : addMember
      const res = await func(formData)
      submitting.value = false
      if (res.success) {
        ElMessage.success('操作成功')
        closeDiaolog()
        loadList()
      }
    }

    // 编辑
    // -------------------------------------------------
    const currentRow = ref<Member | undefined>(undefined)
    const handleEdit = (row: Member) => {
      currentRow.value = row
      openDialog()
    }
    const handleDelete = async (row: Member) => {
      const res = await deleteMember(row.id)
      if (res.success) {
        ElMessage.success('操作成功')
        loadList()
      }
    }
    return {
      currentUserId,
      searchFormData,
      loadList,
      loading,
      roles,
      rows,
      pager,
      fields,
      submitting,
      dialogVisible,
      openDialog,
      closeDiaolog,
      handleSubmit,
      handleSizeChange,
      handleCurrentChange,
      handleEdit,
      handleDelete,
      currentRow
    }
  }
})
</script>
